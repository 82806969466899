
import { defineComponent } from "vue";

export default defineComponent({
  name: "Privacy",
  setup() {
    const url = process.env.VUE_APP_API.slice(0, -1);
    return {
      url: `<a href="${url}">${url.replace(/http:\/\/|https:\/\//, "")}</a>`,
      contact: `<a>${process.env.VUE_APP_EMAIL_ADDRESS}</a>`,
      appName: process.env.VUE_APP_NAME,
    };
  },
});
