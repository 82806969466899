import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b142e500"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper p-py-6 p-px-4" }
const _hoisted_2 = { class: "container p-text-justify" }
const _hoisted_3 = { class: "p-d-flex p-justify-center p-align-center p-flex-column" }
const _hoisted_4 = { class: "p-text-center p-text-uppercase p-mb-0" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "p-mt-6" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "p-mt-6" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "p-mt-6" }
const _hoisted_12 = { class: "rights" }
const _hoisted_13 = { class: "p-mt-6" }
const _hoisted_14 = { class: "p-mt-6" }
const _hoisted_15 = { class: "p-mt-6" }
const _hoisted_16 = { class: "p-mt-6" }
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("workspaceLayout.privacy")), 1),
        _createVNode(_component_Divider, { style: {"width":"200px"} })
      ]),
      _createElementVNode("p", {
        class: "p-mt-5",
        innerHTML: _ctx.$t('privacy.body11', { url: _ctx.url, appName: _ctx.appName })
      }, null, 8, _hoisted_5),
      _createElementVNode("p", {
        class: "p-text-uppercase",
        innerHTML: _ctx.$t('privacy.body12', { url: _ctx.url })
      }, null, 8, _hoisted_6),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body13")), 1),
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("privacy.title2")), 1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('privacy.body2', { url: _ctx.url })
      }, null, 8, _hoisted_8),
      _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t("privacy.title3")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body31")), 1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('privacy.body32', { contact: _ctx.contact })
      }, null, 8, _hoisted_10),
      _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t("privacy.title4")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.subtitle4")), 1),
      _createElementVNode("ul", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('privacy.body41'), (v, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(v), 1))
        }), 128))
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body42")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body43")), 1),
      _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t("privacy.title5")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body5")), 1),
      _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("privacy.title6")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body6")), 1),
      _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.$t("privacy.title7")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("privacy.body7")), 1),
      _createElementVNode("h2", _hoisted_16, _toDisplayString(_ctx.$t("privacy.title8")), 1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('privacy.body8', { contact: _ctx.contact })
      }, null, 8, _hoisted_17),
      _createVNode(_component_Button, {
        icon: "pi pi-home",
        class: "p-button-success p-mt-4",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/'))),
        label: _ctx.$t('privacy.back')
      }, null, 8, ["label"])
    ])
  ]))
}